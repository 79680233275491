
var element = document.getElementById('mySwipe');
window.mySwipe = new Swipe(element, {
  startSlide: 0,
  speed: 700,
  auto: 3000,
  continuous: true

});


var box1 = document.getElementById("box1");
var box3 = document.getElementById("box3");

var config = {attributes : true};


var callback = function(observer){

    var transform1 = document.getElementById("box1").style.transform;

    var transform2 = document.getElementById("box2").style.transform;

    var transform3 = document.getElementById("box3").style.transform;

    var transform4 = document.getElementById("box4").style.transform;

    var transform5 = document.getElementById("box5").style.transform;

    var transform6 = document.getElementById("box6").style.transform;



    if(transform1 == "translateX(0px)"){

        document.getElementById("B1").classList.add("slideactive");
        document.getElementById("B2").classList.remove("slideactive");
        document.getElementById("B3").classList.remove("slideactive");
        document.getElementById("B4").classList.remove("slideactive");
        document.getElementById("B5").classList.remove("slideactive");
        document.getElementById("B6").classList.remove("slideactive");

    }
    if(transform2 == "translateX(0px)"){

        document.getElementById("B1").classList.remove("slideactive");
        document.getElementById("B2").classList.add("slideactive");
        document.getElementById("B3").classList.remove("slideactive");
        document.getElementById("B4").classList.remove("slideactive");
        document.getElementById("B5").classList.remove("slideactive");
        document.getElementById("B6").classList.remove("slideactive");

    }
    if(transform3 == "translateX(0px)"){

        document.getElementById("B1").classList.remove("slideactive");
        document.getElementById("B2").classList.remove("slideactive");
        document.getElementById("B3").classList.add("slideactive");
        document.getElementById("B4").classList.remove("slideactive");
        document.getElementById("B5").classList.remove("slideactive");
        document.getElementById("B6").classList.remove("slideactive");

    }
    if(transform4 == "translateX(0px)"){

        document.getElementById("B1").classList.remove("slideactive");
        document.getElementById("B2").classList.remove("slideactive");
        document.getElementById("B3").classList.remove("slideactive");
        document.getElementById("B4").classList.add("slideactive");
        document.getElementById("B5").classList.remove("slideactive");
        document.getElementById("B6").classList.remove("slideactive");

    }
    if(transform5 == "translateX(0px)"){

        document.getElementById("B1").classList.remove("slideactive");
        document.getElementById("B2").classList.remove("slideactive");
        document.getElementById("B3").classList.remove("slideactive");
        document.getElementById("B4").classList.remove("slideactive");
        document.getElementById("B5").classList.add("slideactive");

    }
    if(transform6 == "translateX(0px)"){

        document.getElementById("B1").classList.remove("slideactive");
        document.getElementById("B2").classList.remove("slideactive");
        document.getElementById("B3").classList.remove("slideactive");
        document.getElementById("B4").classList.remove("slideactive");
        document.getElementById("B5").classList.remove("slideactive");
        document.getElementById("B6").classList.add("slideactive");

    }

};


var observer = new MutationObserver(callback);

observer.observe(box1, config);
observer.observe(box3, config);
